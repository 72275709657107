/* first break point at 1606px */
@media (max-width: 1606px) {
  div.container {
    max-width: 1155px;
  }
  .menu {
    width: 580px;
  }
  .menu #top-menu > li > a {
    font-size: 2.0rem;
  }
  #header {
    #header_logo {
      line-height: 136px;
    }
    #search_widget {
      width: 180px;
      margin-bottom: 16px;
    }
    .header-nav2 > div {
      clear: left;
      margin-top: 10px;
    }
    .user-info a.logged span {
      font-size: 1.2rem;
    }
    .header-nav1 {
      margin-bottom: 24px;
    }
    .header-nav2 {
      width: 180px;
    }
  }
  #content-wrapper.left-column, #content-wrapper.right-column {
    width: 765px;
  }
  .cart-grid-right, #content > .row > .col-lg-3 {
    width: 195px;
  }
  .cart-grid-body, #content > .row > .col-lg-9 {
    width: 975px;
  }
  #content > .row > .col-lg-9, #content > .row > .col-lg-3 {
    float: left;
  }
}
@media (max-width: 1606px) and (min-width: 1206px){
  .page-product {
    .sidebar {
      width: 180px;
    }
    &.layout-left-column #content-wrapper, &.layout-right-column #content-wrapper {
      width: 960px;
    }
  }
}

/* second break point */
@media (max-width: 1206px) {
  div.container {
    max-width: 960px;
  }
  .menu #top-menu > li > a {
    font-size: 1.8rem;
  }
  #header {
    #header_logo {
      width: 180px;
      margin-right: 15px;
    }
  }
  .sidebar {
    width: 180px;
  }
  #content-wrapper.left-column.right-column {
    width: 570px;
  }
  .cart-grid-body, #content > .row > .col-lg-9 {
    width: 780px;
  }
  .page-customer-account #content-wrapper.no-sidebar, .page-my-account #content-wrapper.no-sidebar, .page-addresses #content-wrapper.no-sidebar {
    max-width: none;
  }
}
@media (max-width: 1206px) and (min-width: 1024px) {
  .no-sidebar .products .product-miniature,
  .left-column.right-column .products .product-miniature {
    &:nth-of-type(2n) {
      height: 180px;
      width: 180px;
      .product-description {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        background: rgba(0,0,0,0.5) !important;
        text-shadow: 0 0 2px rgba(0,0,0,0.5);
        animation: none;
        -webkit-animation: none;
        opacity: 0;
        transition: opacity 200ms ease-in-out 0s, visibility 0ms ease-in-out 200ms;
        visibility: hidden;
      }
      &:hover, &:focus {
        .product-description {
          opacity: 1;
          visibility: visible;
          transition-delay: 0s;
        }
      }
    }
  }
}
/* third break point */
@media only screen and (max-width: 1023px) {
  div.container {
    max-width: 765px;
  }
  .menu {
    width: 375px;
  }
  .layout-both-columns .sidebar, .sidebar, .sidebar.sidebar-right-column {
    width: auto;
    float: none;
    margin-right: -15px;

    &:after {
      content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
    }

    & > div, & > section {
      float: left;
      clear: none;
      margin-right: 15px;
    }

    #search_filters_wrapper {
      float: none;
      width: auto;
      clear: both;

      &:after {
        content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
      }
      .facet {
        float: left;
        width: 25%;
        padding: 0 5px;

        &:nth-of-type(4n+1) {
          clear: left;
        }
      }

      &:first-child {
        float: none;
        width: auto;
        clear: both;

        & ~ div, & ~ section {
          clear: none;
          float: left;
        }
      }
    }
  }
  #content-wrapper, #content-wrapper.left-column.right-column {
    width: auto;
    float: none;
  }
  .cart-grid-right, #content > .row > .col-lg-3,
  .cart-grid-body, #content > .row > .col-lg-9 {
    float: none;
    width: auto;
  }
}

/*** Mobile ***/
@media (max-width: 767px) {
  h1, .h1 {
    font-size: 2.0rem;
    line-height: 1;
  }
  body > main {
    display: block;
    padding: 0 10px;
  }
  #header {
    margin-bottom: 10px;
    & > .container {
      padding: 0;
    }
    #header_logo {
      padding: 40px 0 20px;
      width: auto;
      float: none;
      min-height: 0;
      line-height: normal;
      text-align: center;
      margin: 0;
    }
    #toggle_menu {
      display: block;
    }
    [id^=_desktop] {
      display: none;
    }

    #contact-link a {
      &:before {
        content: '\f003';
        font-family: FontAwesome;
        margin-right: 3px;
        font-weight: normal;
        font-size: 1.2em;
      }
    }

    .dropdown {
      border-radius: 2px;
    }
    .header-nav1 {
      margin: 0;
      height: 0;
    }
    .header-nav2 {
      width: auto;

      & > div {
        clear: none;
        width: auto;
      }
    }
    .user-info a.logged {
      span {
        font-size: inherit;
      }
      &:after {
        content: none;
      }
    }
    .user-info ul {
      display: none;
    }

    #search_widget {
      float: none;
    }
    #header_logo_mobile {
      padding: 0 30px 20px 0;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 150px;
      }
    }
  }

  .sidebar, #wrapper #content-wrapper {
    float: none;
    width: auto;
  }

  .sidebar {
    margin-right: 0;
  }

  #content-wrapper .block-product-list {
    .bx-wrapper {
      max-width: none !important;
    }
  }

  .homeslider-container {
    float: none;
    width: auto;
    margin-right: 0;

    .bx-controls-direction {
      display: none;
    }
    .description {
      h1, h2 {
        margin-bottom: 0;
      }
    }
  }
  .images-container .bx-controls-direction {
    display: none;
  }

  /* footer responsive */
  .footer-container .footer {
    margin-right: 0;
  }
  .footer-container .footer > div {
    float: none;
    width: auto;
    padding: 0;
    margin-bottom: 0;
  }
  .footer-container .block-social {
    &:after {
      content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
    }
    li:nth-child(4n) {
      margin-right: 9px;
    }
  }
  .footer-container {
    font-size: 1.2rem;

    .toggle_title {
      cursor: pointer;

      &:before {
        content: '\f0fe';
        font-family: FontAwesome;
        display: inline-block;
        margin: 0px 2px 0 -3px;
        padding: 5px;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .opened .toggle_title:before {content:'\f146';}
    .toggle_content {
      display: none;
      margin-left: 25px;
      margin-bottom: 20px;
      &:after {
        content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
      }
    }
    li {
      a {
        display: block;
        padding: 5px 0;
      }
    }
  }

  .sidebar #search_filters > h4:first-child {display: block !important;}
  #search_filters .collapse {
    display: none;
    &.in {
      display: block;
    }
  }
  .sidebar.sidebar-left-column {
    margin-bottom: 32px;
  }
  .sidebar.sidebar-right-column > section {
    float: none;
    width: auto;
    margin-right: 0;
  }

  #search_filters .facet {
    position: relative;
    margin-bottom: 0;

    .facet-title {
      padding: 10px 0;
      min-height: 37px;
      padding-right: 50px;
      margin: 0;
    }
    .collapse {
      padding: 10px 0;
    }
    .collapse-icons {
      position: absolute;
      height: 37px;
      width: 40px;
      cursor: pointer;
      right: 0;
      top: 0;
      text-indent: -999em;
      overflow: hidden;
      line-height: 37px;
      text-align: left;

      &:before {
        content: '\f107';
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-indent: 0;
        font-family: FontAwesome;
      }

      i {
        display: none;
      }
    }
  }
  #wrapper #search_filters {
    .facet {
      float: none;
      width: auto;
      padding-right: 0;
      padding-left: 0;
    }
  }
  #search_filters .facet:last-child .facet-title {
    border-bottom: none;
  }
  .sidebar {
    margin-right: 0 !important;
    & > div, & > section {
      float: none !important;
      margin-right: 0 !important;
      width: auto !important;
    }
    .bx-wrapper {
      padding-bottom: 38px;
      .bx-controls-direction {
        opacity: 1;
      }
    }
    .products .product-miniature .product-description .product-desc {
      display: block;
    }
  }

  .sidebar .block-categories li a {
    padding-top: 7px;
    padding-bottom: 7px;

    &:before {
      top: 8px;
    }
  }

  /* product list */
  #content-wrapper {
    .products {
      margin: 0;
    }
    .products .product-miniature {
      width: 100%;
      margin: 0 0 10px;
      float: none;

      .thumbnail-container {
        width: 50%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .product-description {
        width: 50%;
        height: calc(50vw - 10px);

        @supports(display: flex) {
          height: auto;
          min-height: 0;
        }
      }
      &:nth-child(2n+1) .product-description {
        background: $bg-bloc2;
        &:after {
          border-right-color: $bg-bloc2;
        }
      }
      &:nth-child(2n) .product-description {
        background: $bg-bloc1;
        &:after {
          border-right-color: $bg-bloc1;
        }
      }
      .functional-buttons {
        // display: none;
        opacity: 1;
      }
      .product-desc {
        font-size: 0.8em;
      }
      .product-price-and-shipping {
        right: 10px;
      }
      .regular-price {
        display: inline-block;
      }
    }
  }

  #wrapper .breadcrumb {
    margin-top: 0;
  }

  .products-selection {
    .showing {
      display: none;
    }
  }

  .pb-left-column {
    width: auto;
    margin-right: 0;
    float: none;
  }

  .sidebar .products .product-miniature .product-desc {
    display: block;
  }
  #blockcart-modal .divide-right {
    border-right: none;
  }
  #blockcart-modal .cart-content {
    padding-left: 0;
  }
  #blockcart-modal .modal-dialog {
    width: auto;
  }
  .product-line-grid-right,
  .cart-grid-body .cart-line-product-actions {
    text-align: right;
  }

  .block-category.has-img {
    float: none;
    width: auto;
    margin-right: 0;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .cart-grid-body ul.cart-items li > div {
    padding: 15px 5px;
    vertical-align: top;
  }
  body#checkout #order-summary-content .order-confirmation-table {
    padding: 0;
  }
  .order-confirmation-table .order-line > div {
    padding: 5px;
    font-size: 12px;
  }

  .page-my-account #content .links {
    margin-right: 0;

    > a {
      width: 50%;
      width: calc(50vw - 15px);
      margin-right: 0;
      margin-bottom: 10px;
      min-height: calc(50vw - 10px);
      clear: left;

      &:before {
        margin: 10px 0;
      }

      &:nth-child(2n) {
        float: right;
        clear: none;
      }
    }
  }
  .form-control-comment {
    display: block;
  }

  body #featuredcategories ul:after {
    content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
  }
  #featuredcategories .products .product-miniature .category-title {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
  body #featuredcategories .products .product-miniature {
    width: calc(50% - 5px);
    float: left;
    margin: 0 0 10px;
    &:nth-child(2n) {
      float: right;
    }

    & > a {
      display: block;
    }
  }
  @supports(not(width: calc(50% - 15px))) {
    body #featuredcategories .products .product-miniature {
      width: 48%;
      padding-right: 2%;
      margin-right: 0;
    }
  }
}