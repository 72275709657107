body,
html {
  height: 100%;
}
html {
  font-size:62.5%;
}
body {
  font-family: $main-font;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  background: $background-color;
  line-height: normal;
}
ul {
  list-style: none;
  padding-left: 0;
}
a {
  color: $link-color;

  &:hover, &:active, &:focus {
    color: $link-hover-color;
    text-decoration: none;
  }
}
p {
  line-height: 1.4;
  margin: 0 0 1.4em;
}
input[type=text],
input[type=email],
input[type=password],
input[type=tel],
textarea,
select.form-control {
  max-width: 400px;
  border: none;
  line-height: normal;
  padding: 5px 10px;
  border: 1px solid rgba($border-color, 0.2);
  transition: border-color 0.15s ease-in-out;
  font-size: 1.2rem;
  color: $text-color;

  &:focus {
    border-color: $border-color;
    outline: none;
    box-shadow: none;
    color: inherit;
  }
}
select.form-control:not([size]):not([multiple]) {
  height: auto;
}
input, textarea {
  &::-webkit-input-placeholder {
    font-style: italic;
    color: inherit;
  }
  &:-moz-placeholder {
    font-style: italic;
    color: inherit;
  }
  &::-moz-placeholder {
    font-style: italic;
    color: inherit;
  }
  &:-ms-input-placeholder {
    font-style: italic;
    color: inherit;
  }
}
label {
  margin-bottom: 0;
}

h2, .h2, h1, .h1 {
  font-weight: normal;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-family: $second-font;
}
h1, .h1 {
  font-size: 4.0rem;
  line-height: 1;
  margin-bottom: 15px;
  font-weight: 300;
}

h2, .h2 {
  font-size: 1.8rem;
  line-height: normal;
}
h3, .h3 {
  font-size: 1.6rem;
  line-height: normal;
}
h4, .h4 {
  font-size: 1.4rem;
  line-height: normal;
}
h5, .h5, h6, .h6 {
  font-size: inherit;
  font-weight: bold;
  line-height: normal;
}

hr {
  border-top-color: rgba($border-color, 0.2);
}

.container {
  padding: 0;  
  max-width: 1545px;

  &:after {
    content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
  }
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
}
.color,
.custom-checkbox input[type="checkbox"] + span.color {
  width: 2.0rem;
  height: 2.0rem;
  display: inline-block;
  margin: 0.5rem;
  border: 1px solid rgba(0,0,0,0.05);
  cursor: pointer;
  background-size: contain;
  &.active,
  &:hover {
    border: 2px solid $gray-darker;
  }
}
.facet-label {
  &.active,
  &:hover {
    .custom-checkbox span.color {
      border: 2px solid $gray-darker;
    }
  }
}
.btn {
  white-space: normal;
}
.btn:hover, .btn:focus, .btn:active {
  outline: none;
}
.btn-primary,
.btn-secondary {
  text-transform: uppercase;
  font-weight: normal;
  padding: 0.7rem 1.7rem;
  font-size: 1.4rem;
  transition: all 0.15s ease-in-out;
}
.btn-primary {
  background-color: $btn-primary-bg;
  color: $btn-primary-color;

  &:hover, &:focus {
    background-color: $btn-primary-hover-bg;
  }
}
.card {
  border: none;
  background: $block-bg;
  color: $block-text;
}
.card-block {
  padding: 15px;
  &:last-child {
    border-bottom: none;
  }
  .aeuc_scart {
    @extend .block_title;
  }
}
.card {
  .card-block {
    border-bottom: rgba($border-color, 0.2) solid 1px;
    &:last-child {
      border-bottom: none;
    }
  }
}
small.label, small.value {
  font-size: 1.3rem;
}
.form-control-label {
  padding-top: 0.5rem;
}
select {
  padding: 0;
  border: 1px solid rgba($border-color, 0.2);
  padding: 2px;
  width: 300px;
  transition: border-color 0.15s ease-in-out;

  &:hover, &:focus {
    border-color: $border-color;
  }
}
.form-control-valign {
  padding-top: 0.8rem;
}
.form-control-comment {
  padding-top: 0.8rem;
  opacity: 0.66;
  display: inline-block;
}
.form-control-submit {
  &.disabled {
    background: $brand-info;
    color: white;
  }
}
.form-group {
  &.has-error {
    input,
    select {
      outline: 0.3rem solid $brand-danger;
    }
    .help-block {
      color: $brand-danger;
    }
  }
}
.group-span-filestyle {
  label {
    margin: 0;
  }
  .btn-default {
    background: #ccc;
    color: white;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 1.4rem;
    padding: 0.4rem 0.6rem;
    white-space: nowrap;
    &:hover, &:focus {
      background-color: #ddd;
    }
  }
}
.form-control {
  font-size: 1.2rem;
}
.radio-inline {
  margin-right: 5px;
}
.custom-radio {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: rgba(51,51,51,0.2) 1px solid;
  transition: border-color 0.15s ease-in-out;
  background: white;
  margin-right: 2.0rem;
  &:hover {
    border-color: rgba(51,51,51,1);
  }
  input[type="radio"] {
    opacity: 0;
    cursor: pointer;
  }
  input[type="radio"]:checked + span {
    display: block;
    background-color: #333;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -5px;
  }
}
.custom-checkbox {
  position: relative;
  input[type="checkbox"] {
    margin-top: 0.4rem;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 2;
    + span {
      margin-right: 3px;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      border: 1px $gray-darker solid;
      background: white;
      .checkbox-checked {
        display: none;
        margin: -0.4rem -0.2rem;
        font-size: 1.6rem;
        color: $gray-darker;
        width: 1.5rem;
        height: 1.5rem;
        text-indent: -999em;
        overflow: hidden;

        &:before {
          content: '\f00c';
          font-family: FontAwesome;
          position: absolute;
          left: -1px;
          top: -4px;
          right: 0;
          bottom: 0;
          text-align: left;
          text-indent: 0;
        }
      }
      + label {
        display: inline;
      }
    }
    &:checked + span {
      .checkbox-checked {
        display: block;
      }
    }
  }
  input[type="radio"] {
    margin-top: 0.4rem;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 2;
    + span {
      margin-right: 3px;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
      cursor: pointer;
      border: 1px $gray-darker solid;
      background: white;
      border-radius: 50%;
      position: relative;
      .checkbox-checked {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -0.4rem;
        margin-top: -0.4rem;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background: $gray-darker;
        text-indent: -999em;
        overflow: hidden;
      }
      + label {
        display: inline;
      }
    }
    &:checked + span {
      .checkbox-checked {
        display: block;
      }
    }
  }
  label {
    text-align: left;
  }
}
.text-muted {
  font-size: inherit;
  color: inherit !important;
  opacity: 0.66;
}
a.text-muted:focus, a.text-muted:hover {
  opacity: 1;
  color: inherit !important;
}
.done {
  color: $brand-success;
  display: inline-block;
  padding: 0 1.3rem;
  margin-right: 2.5rem;
}
.thumb-mask {
  > .mask {
    position: relative;
    width: 5.5rem;
    height: 5.5rem;
    overflow: hidden;
    border: $gray-light 1px solid;
    margin: $small-space 0;
    img {
      width: 55px;
      height: 55px;
    }
  }
}
.definition-list {
  dl {
    display: flex;
    flex-wrap: wrap;
    dt {
      font-weight: normal;
    }
    dd,
    dt {
      flex: 0 0 45%;
      background: $gray-light;
      padding: 1.0rem;
      margin: 0.2rem;
      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }
  }
}
.help-block {
  margin-top: $small-space;
}
.btn.disabled,
.btn.disabled:hover {
  background: $gray;
}
.alert-warning {
  .alert-text {
    font-size: 1.5rem;
    padding-top: $small-space;
  }
  .alert-link {
    border-radius: 2px;
    border-width: 2px;
    margin-left: $small-space;
    padding: $extra-small-space $medium-space;
    font-weight: 600;
    font-size: 1.3rem;
    color: $btn-tertiary-color;
  }
  ul li:last-child .alert-link {
    color: white;
  }
  .warning-buttons {
    margin-top: $extra-small-space;
  }
}
.btn-warning {
  @include transition(all .4s ease-in-out);
}
.btn-tertiary-outline {
  color: $btn-tertiary-color;
  background-image: none;
  background-color: transparent;
  border-color: $btn-tertiary-color;
  border: 0.15rem solid $btn-tertiary-color;
  @include transition(all .4s ease-in-out);
  &:hover {
    border-color: $btn-tertiary-hover;
    color: $btn-tertiary-hover;
  }
}
.alert {
  font-size: 1.3rem;
}


#toTop {
  position: absolute;
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 38px;
  height: 38px;
  background-color: transparent;
  cursor: pointer;
  display: none;
  font-size: 1px;
  text-indent: -999em;
  overflow: hidden;
  visibility: hidden;
  transition: $hover-transition;
  z-index: 100;
  &:after {
    font-family: "pictos";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: none;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;

    content: '\E82D';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 38px;
    text-align: center;
    text-indent: 0;
    font-size: 24px;
  }

  @media only screen and (min-width: 1200px) {
    visibility: visible;
  }
}
#toTop:hover {
  background-color: rgba(0,0,0,0.85);
  color: white;
}

.modal-backdrop {
  background-color: $modal-backdrop-bg;
  background: -moz-linear-gradient($bg-bloc1, $bg-bloc2);
  background: -webkit-linear-gradient($bg-bloc1, $bg-bloc2);
  background: -ms-linear-gradient($bg-bloc1, $bg-bloc2);
  background: -o-linear-gradient($bg-bloc1, $bg-bloc2);
  background: linear-gradient($bg-bloc1, $bg-bloc2);;
}
.modal {
  &.fade .modal-dialog {
    transform: translate3d(-100%, 0, 0);
  }
  &.in .modal-dialog {
    transform: translate3d(0, 0, 0)
  }

  & > .close {
    position: absolute;
    top: 30px;
    right: 30px;
    color: white;
    height: 40px;
    width: 40px;
    text-align: center;
    border: solid 1px white;
    border-radius: 50%;
    opacity: 0.75;

    span {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 50%;
      top: 50%;
      text-indent: -999em;
      overflow: hidden;
      margin-top: -10px;
      margin-left: -10px;
      transform: rotate(45deg);
      transition: transform 0.25s ease-in-out;

      &:before {
        content: '';
        width: 2px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -1px;
        background: white;
      }
      &:after {
        content: '';
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -1px;
        background: white;
      }
    }

    &:hover, &:focus {
      outline: none;
      span {
        transform: rotate(225deg);
      }
    }
    &:active {
      outline: none;
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .close {
      top: 10px;
    }
    .modal-dialog {
      margin-top: 60px;
    }
  }
}
.modal-backdrop.in {
  opacity: 0.7;
}
.modal-content {
  border-radius: 0;
  border: none;
  box-shadow: 0 3px 15px rgba(0,0,0,0.4);
}
.label.back {
  &:before {
    content: '\f053';
    display: inline;
    margin-right: 4px;
    font-family: FontAwesome;
  }
}
.forgot-password a {
  text-decoration: underline;
  font-size: 0.85em;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.block_title {
  @extend %block-title;
}

.alert {
  border: none;

  & > ul:last-child {
    margin-bottom: 0;
  }
}

ul.ui-autocomplete {
  background: $background-color;
  color: $text-color;
  border: $border-color solid 1px;
  border-radius: 0;
  z-index: 1000;
  a {
    border-radius: 0;
    background: transparent;
    transition: background 0.15s ease-in-out;
    border: none !important;

    &:hover, &:focus {
      background: rgba(0,0,0,0.3) !important;
    }
  }
}

.bold {
  font-weight: bold;
}

.modal-header {
  border-bottom: rgba($border-color, 0.2) solid 1px;
}

a .icon {
  display: inline-block;
  padding: 5px;
  font-size: 1.6rem;
  font-family: FontAwesome;
  &.view:before {
    content: '\f06e';
  }
  &.reload:before {
    content: '\f021';
  }
}

.sitemap {
  ul {
    margin-bottom: 3em;
  }
  li {
    a {
      display: block;
      padding: 10px 0;
      border-bottom: rgba($border-color, 0.2) solid 1px;
    }
  }
  ul ul {
    padding-left: 20px;
    margin-bottom: 1.5em;
  }
}

#notifications .container:after {content:none;}

.nav-link.active {
  font-weight: bold;
}

label a {
  text-decoration: underline;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.facet-label a {
  text-decoration: none;
}