$gray-darker: #414141;
$gray-dark: #878787;
$gray: #acaaa6;
$gray-light: #ebebeb;
$gray-lighter: #f6f6f6;
$brand-primary: #2fb5d2;
$brand-secondary: #f39d72;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$label-color: $gray-darker;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.5rem;
$small-space: 1.0rem;
$medium-space: 2.0rem;
$large-space: 3.0rem;
$extra-large-space: 4.0rem;
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1.4rem;
$font-size-lg: 1.5rem;
$font-size-sm: 1.4rem;
$font-size-xs: 1.3rem;
$font-size-h1: 2.2rem;
$font-size-h2: 2.0rem;
$font-size-h3: 1.8rem;
$font-size-h4: 1.8rem;
$font-size-h5: 1.6rem;
$font-size-h6: 1.5rem;
$display1-size: 5.0rem;
$display1-weight: 600;
$display2-size: 3.5rem;
$display2-weight: 400;
$display3-size: 2.5rem;
$display3-weight: 400;
$display4-size: 2.0rem;
$display4-weight: 400;
$text-muted: $gray;

$main-font: 'Open Sans', Arial, Helvetica, sans-serif;
$second-font: $main-font;
$background-color: #ffffff;
$text-color: #212121;
$border-color: #212121;
$link-color: #212121;
$link-hover-color: #000000;
$menu-hover-bg: #212121;
$menu-hover-text: #ffffff;
$block-bg: #dddddd;
$block-text: #222222;
$bg-bloc1: #556270;
$bg-bloc2: #c02942;
$bg-bloc-hover: #212121;
$txt-bloc-color: #ffffff;

$btn-primary-color: $txt-bloc-color;
$btn-primary-bg: $bg-bloc2;
$btn-primary-hover-bg: $bg-bloc-hover;

$hover-transition: all 200ms ease-in-out 0s;

$grid-gutter-width: 15px;

// photoswipe settings
$pswp__show-hide-transition-duration: 333ms !default;
$pswp__controls-transition-duration: 333ms !default;
$pswp__background-color: #000 !default;
$pswp__placeholder-color: #222 !default;
$pswp__box-sizing-border-box: true !default; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
$pswp__root-z-index: 1500 !default;
$pswp__assets-path: '../img/' !default; // path to skin assets folder (preloader, PNG and SVG sprite)
$pswp__error-text-color: #CCC !default; // "Image not loaded" text color
$pswp__include-minimal-style: true !default;