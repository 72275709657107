.pull-xs-left {
  float: left;
}
.pull-xs-right {
  float: right;
}
.close {
	position: relative;
	z-index: 2;
	width: 50px;
	height: 40px;
	color: inherit;
	text-shadow: none;

	&:hover, &:focus {
		color: inherit;
	}

	span {
		display: none;
	}

	&::before {
		content: '\E812';
		font-family: pictos;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		text-align: center;
		line-height: 40px;
		font-size: 20px;
		font-weight: normal;
	}
}
.container {
	width: auto;
}

.modal-footer {
	border-top: rgba(33, 33, 33, 0.2) 1px solid;
}