.cart-summary-line {
  @include clearfix;
  clear: both;
  .label {
    padding-left: 0;
    font-weight: normal;
    white-space: inherit;
  }
  .value {
    color: $gray-darker;
    float: right;
  }
  &.cart-summary-subtotals {
    .label,
    .value {
      font-weight: normal;
    }
  }
}
/** CART BODY **/
.cart-grid-body {
  .cart-container {
    margin-bottom: 2em;
    background: $background-color;
    color: $text-color;
  }
  ul.cart-items {
    margin: 0;
    display: table;
    width: 100%;

    li {
      display: table-row;

      & > div {
        display: table-cell;
        float: none;
        width: auto;
        border-bottom: rgba($border-color, 0.2) solid 1px;
        padding: 15px;
        vertical-align: middle;

        &:first-child {
          width: 50px;
          img {
            max-width: 200px;
          }
        }
      }
    }
  }
  .product-name {
    font-weight: bold;
  }
  .cart-line-product-actions  {
    text-align: center;
  }
  .remove-from-cart {
    &:before {
      content: '\f014';
      font-family: FontAwesome;
      font-size: 1.8rem;
      font-weight: normal;
    }
    i {
      display: none;
    }
  }
}
/** CART RIGHT **/
.cart-summary-line {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}
.cart-total {
  font-weight: bold;
  font-size: 1.2em;

  .label {
    font-weight: bold;
  }
}

.cart-grid-right {
  hr {
    margin: 0;
  }
}
.block-promo {
  .promo-code {
    padding: 1.60rem;
    background: $gray-light;
    .alert-danger {
      position: relative;
      margin-top: 2.0rem;
      background: $brand-danger;
      color: white;
      display: none;
      &::after {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color:  $brand-danger;
        border-width: 10px;
        margin-left: -10px;
      }
    }
  }
  .promo-input {
    color: $gray-darker;
    border: $gray 1px solid;
    height: 4.0rem;
    text-indent: 1.0rem;
    width: 60%;
    + button {
      margin-top: -4px;
      text-transform: capitalize;
    }
  }
  .cart-summary-line .label,
  .promo-name {
    color: $brand-warning;
    font-weight: 600;
    a {
      font-weight: normal;
      color: $gray-darker;
      display: inline-block;
      opacity: 0.7;
    }
  }
  .promo-code-button {
    padding-left: 2.0rem;
    margin-bottom: 2.0rem;
    display: inline-block;
  }
}
/** CONTENT LEFT **/
.product-line-grid-left {
  img {
    max-width: 100%;
  }
}
/** CONTENT BODY **/
.product-line-grid-body {
  > .product-line-info {
    > .label {
      padding: 0;
      line-height: inherit;
      text-align: left;
      white-space: inherit;
    }
    > .out-of-stock {
      color: red;
    }
    > .available {
      color: $brand-success;
    }
    > .unit-price-cart {
      padding-left: 0.3125rem;
      font-size: 0.875rem;
      color: $gray-dark;
    }
  }
}
/** CONTENT LEFT **/
.product-line-grid-right {
  .bootstrap-touchspin {
    width: 5.5rem;
    float: left;
    > .form-control,
    > .input-group {
      width: 8.0rem;
    }
    > .input-group-btn-vertical {
      width: auto;
    }
  }
  .cart-line-product-actions,
  .product-price {
    line-height: 30px;
    .remove-from-cart {
      display: inline-block;
      opacity: 0.7;
    }
  }
}

.label.back {
  display: inline-block;
  margin-bottom: 2em;
}