.menu {
  float: left;
  width: 765px;
  position: relative;
  z-index: 3;
  
  #top-menu {
    li {
      position: relative;
    }
    & > li {
      float:left;
      &:hover {
        z-index: 2;
      }
      
      @media (min-width: 1607px) {
        // query selector, if more than 6 elements
        &:nth-last-child(n+7), &:nth-last-child(n+7) ~ li {
          & > a {
            font-size: 2.0rem;
          }
        }
      }
      @media (min-width: 1207px) {
        // query selector, if more than 7 elements
        &:nth-last-child(n+8), &:nth-last-child(n+8) ~ li {
          & > a {
            font-size: 1.8rem;
          }
        }
      }

      & > a {
        display: block;
        padding: 1px 10px;
        font-family: $second-font;
        font-size: 2.2rem;
        // text-transform: lowercase;
        transition: $hover-transition;
      }

      &.current > a {
        font-weight: bold;
      }

      & > a:hover,
      & > a:focus,
      &:hover > a {
        background: $menu-hover-bg;
        color: $menu-hover-text;

        &:after {
          border-top-color: $menu-hover-text;
        }
      }
    }

    .has_submenu {
      & > a {
        &:after {
          font-family: "pictos";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: none;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          content: '\E80C';
        }
      }
    }

    .toggle_submenu {
      display: none;
    }

    .sub-menu {
      opacity: 0;
      visibility: hidden;
      transition: opacity 200ms ease-in-out 0s, visibility 0s linear 200ms;
      position: absolute;
      background: $menu-hover-bg;
      color: $menu-hover-text;
      padding: 0;
      left: 0;
      width: 190px;
      text-transform: none; 
      color: #fff;

      a {
        color: inherit;
        padding: 7px 10px;
        opacity: 0.75;
        display: block;

        &:hover, &:focus {
          opacity: 1;
        }
      }

      .has_submenu > a {
        position: relative;
        padding-right: 20px;

        &:after {
          font-family: "pictos", arial;
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: none;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;

          content: '\E84F';
          margin-left: 5px; margin-right: 5px;
          height: 17px; height: 1.7rem;
          line-height: 17px; line-height: 1.7rem;
          font-size: 1.4em;
          float: right;
        }
      }

      .sub-menu {
        left: 190px;
        top: 0;
      }
    }

    li:hover > .sub-menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }
  }
}