.block-category.has-img {
  float: left;
  margin: 0 15px 15px 0;
  clear: both;
  overflow: hidden;
  position: relative;

  h1 {
    position: absolute;
    left: 0;
    top: 15px;
    background: #fff;
    background: rgba(255,255,255,0.75);
    color: #212121;
    padding: 10px 15px;
    z-index: 10;
    max-width: 100%;
  }
  .category-description {
    background: #000;
    background: rgba(0,0,0,0.5);
    color: #fff;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 12px 15px;
    position: absolute;
    animation: fadeIn3 1.9s ease-in-out 0s 1;
    animation-fill-mode: forwards;
  }
}

.products-selection {
  clear: both;
  padding: 15px 0;

  &:after {
    content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
  }
  
  .showing {
    padding-top: 2px;
  }
  .products-sort {
    float: right;
    white-space: nowrap;
    padding-top: 2px;

    button {
      display: none;
    }

    .products-sort-order {
      display: inline-block;
      color: inherit;
    }

    .select-title {
      cursor: pointer;

      &:after {
        content: '';
        border: transparent 5px solid;
        border-width: 8px 4px 0;
        border-top-color: $text-color;
        opacity: 0.75;
        display: inline-block;
      }
    }

    .dropdown-menu {
      white-space: normal;
      right: 0;
      left: auto;
      margin-top: 7px;
      padding: 0;

      a {
        display: block;
        padding: 2px 9px;
        font-size: 1.2rem;
        transition: background-color 0.15s ease-in-out;

        &:first-child {
          padding-top: 7px;
        }

        &:last-child {
          padding-bottom: 7px;
        }

        &:hover, &.current {
          background-color: rgba($text-color, 0.1);
        }
      }
    }
  }
}
.total-products {display: none;}

/* pagination */
.pagination {
  padding: 15px 0;
  margin: 0;
  border-top: rgba($border-color, 0.2) solid 1px;
  clear: both;
  text-align: left;
  display: block;
}
ul.page-list {
  margin: 0; padding: 0;

  li {
    display: inline-block;
    padding: 0 0 0 3px;
    border-left: 1px solid rgba($border-color, 0.2);

    &:first-child {
      border-left-width: 0;
      margin-left: 0;
      & > span, & > a {
        padding-left: 0;
      }
    }

    & > span, & > a {
      padding: 1px 4px;
      display: inline-block;
    }
    & > a {
      &.disabled {
        cursor: default;
      }
    }
    &.current > a {
      font-weight: bold;
    }
  }
}

/* active filters facets */
.active_filters {
  background: $block-bg;
  color: $block-text;
  padding: 10px;
  margin-bottom: 20px;

  .active-filter-title {
    font-size: 1.2rem;
  }

  ul {
    margin-bottom: 0;
    
    a.js-search-link:before {
      content: '\f00d';
      font-family: FontAwesome;

      .material-icons {
        display: none;
      }
    }
  }
}