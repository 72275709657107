.quickview {
  .modal-dialog {
    @media (min-width: 880px) {
      max-width: 800px;
    }
  }
  .modal-header {
    border: none;
    padding: $small-space;
    text-align: center;
    position: relative;
    padding: 10px 30px;
    h1 {
      margin: 0;
      line-height: normal;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .modal-footer {
    border-top: 1px solid rgba($border-color,.2);
  }
  .layer {
    display: none;
  }
  .product-cover {
    border: none;
    width: 80%;
  }
  .images-container {
    display: flex;
    z-index: 1;
    .thumb-container {
      padding: 1px;
      float: none;
      width: auto;
      margin: 0;
    }
    .product-images > li.thumb-container > .thumb {
      margin-bottom: 10px;
      background: white;
    }

    img {
      border: rgba($border-color, 0.2) solid 1px;
      &:hover {
        border-color: $border-color;
      }
    }
  }
  .mask {
    max-height: 275px;
    overflow: hidden;
    margin-left: $small-space;
  }
  .social-sharing {
    margin-top: 0;
    padding-top: 0;
    text-align: center;
  }
}
