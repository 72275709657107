body#checkout {
  section.checkout-step {
    border: 1px $border-color solid;
    background: $background-color;

    .nav-inline .nav-item {
      margin-right: 5px;
      margin-left: 0;
    }

    .nav-inline.m-y-2 {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 2em;
    }

    & + .checkout-step {
      border-top: none;
    }

    .step-title {
      margin: 0;
      padding: 20px;
      opacity: 0.5;
    }
    .step-edit {
      text-transform: lowercase;
      font-weight: normal;
      .edit {
        font-size: $base-font-size;
      }
    }
    .not-allowed {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .content,
    .done,
    .step-edit {
      display: none;
    }
    &.-current {
      .content {
        display: block;
        padding-top: 15px;
        padding: 20px;
      }
    }
    &.-current.-reachable.-complete {
      .done,
      .step-edit {
        display: none;
      }
      .step-number {
        display: inline-block;
      }
      .content {
        display: block;
      }
    }
    &.-reachable {
      .step-title {
        cursor: pointer;
        opacity: 1;
      }
    }
    &.-current {
      .step-title {
        opacity: 1;
      }
    }
    &.-reachable.-complete {
      .step-title {
        &:before {
          content: '\f00c';
          font-family: FontAwesome;
          color: $btn-primary-bg;
        }
        .step-number {
          display: none;
        }
        .step-edit {
          cursor: pointer;
          display: none;
          float: right;
          margin-left: .2rem;
          &:before {
            content: '\f040';
            font-family: FontAwesome;
          }
        }
      }
      .content {
        display: none;
      }
    }
    small {
      color: $gray;
    }
    .default-input {
      min-width: 40%;
      &[name=address1],
      &[name=address2] {
        min-width: 60%;
      }
    }
    .radio-field {
      margin-top: 3.0rem;
      label {
        display: inline;
      }
    }
    .checkbox-field div {
      margin-top: 6.0rem;
    }
    .checkbox-field + .checkbox-field div {
      margin-top: 0;
    }
    .select-field div {
      background: $gray-lighter;
      padding: 1rem 5.0rem;
    }
    .form-footer {
      text-align: center;
    }
    #conditions-to-approve {
      padding-top: 3.0rem;
    }
    .payment-option label {
      display: table-cell;
    }
    .payment-options .custom-radio {
      margin-right: 10px;
    }
    .step-number {
      display: inline-block;
      &:after {
        content: '. ';
      }
    }
    .address-item {
      margin-bottom: $small-space;
      border: rgba($border-color,0.2) solid 2px;
      transition: border-color 0.15s ease-in-out;
      &.selected {
        border: $btn-primary-bg 2px solid;
      }
    }
    .address-alias {
      display: inline-block;
      font-weight: 600;
      margin-bottom: 1.0rem;
    }
    .address {
      margin-left: 2.5rem;
      font-weight: normal;
    }
    .radio-block {
      padding: 1.5rem;
      text-align: left;
    }
    .custom-radio {
      margin-right: 0;
    }
    .delete-address,
    .edit-address {
      color: $gray;
      display: inline-block;
      margin: 0 .5rem;
      .delete,
      .edit {
        font-size: $base-font-size;
      }
    }
    hr {
      margin: 0;
    }
    .address-footer {
      text-align: center;
      padding: 1.0rem;
    }
    #delivery-addresses,
    #invoice-addresses {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }
    p {
      a {
        text-decoration: underline;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
    .delivery-option {
      background: $gray-lighter;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .carrier-delay,
    .carrier-name {
      display: inline-block;
      margin: 0 2.0rem;
      word-break: break-word;
      text-align: left;
    }
    #customer-form,
    #delivery-address,
    #invoice-address,
    #login-form {
      margin-top: 2.5rem;
      .form-control-label {
        text-align: left;
      }
      .radio-inline {
        padding: 0;
      }
    }
    .sign-in {
      font-size: $font-size-sm;
    }
    .forgot-password {
      margin-left: 25%;
      padding-left: 10px;
    }
  }
  .additional-information {
    font-size: $font-size-sm;
    margin-left: 4.6rem;
    margin-top: 2.0rem;
  }
  .condition-label {
    margin-left: 25px;
    margin-top: 1.0rem;
  }
  .cancel-address {
    margin: 1.0rem;
    display: block;
    color: $gray-dark;
    text-decoration: underline;
  }
  .modal-content {
    padding: $medium-space;
  }
  #cart-summary-product-list {

    img {
      max-width: 100px;
    }
    .media-body {
      vertical-align: middle;
    }

    li {
      margin-bottom: 10px;
    }
  }
  #order-summary-content {
    padding-top: 1.5rem;
    h4.h4 {
      margin-top: 1.0rem;
      margin-bottom: 2.0rem;
      color: $gray-darker;
    }
    h4.black {
      color: #000000;
    }
    h4.addresshead {
      margin-top: .3rem;
    }
    .noshadow {
      box-shadow: none;
    }
    #order-items {
      table {
        tr {
          &:first-child {
            td {
              border-top: 0;
            }
          }
        }
      }
    }
    .order-confirmation-table {
      padding: 1rem;
      margin-bottom: 2rem;
      background-color: #fff;
      border: 3px solid rgba($border-color, 0.2);
      border-radius: 0;      
    }
    .summary-selected-carrier {
      margin-bottom: 0.75rem;
      background-color: #fff;
      border: 1px solid rgba($border-color, 0.2);
      border-radius: 0;
      padding: 1rem;      
    }    
    .step-edit {
      display: inline;
      cursor: pointer;
      text-decoration: underline;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
  #gift_message{
    max-width: 100%;
    border-color: $gray-darker;
  }
}
/**** ORDER CONFIRMATION *****/
#order-details {
  > .card-title {
    margin-bottom: $large-space;
  }
  ul {
    margin-bottom: $medium-space;
    li {
      margin-bottom: $small-space;
    }
  }
}
#order-items {
  table {
    width: 100%;
    tr {
      height: $large-space;
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
#order-confirmation {
  #registration-form {
    width: 50%;
    margin: 0 auto;
  }
}
@include media-breakpoint-down(md) {
  .done {
    margin: 0;
    padding: 0;
  }
  .cart-grid-body {
    width: 100%;
  }
  .cart-grid-right {
    width: 100%;
  }
  body#checkout section.checkout-step.-reachable.-complete .step-title .step-edit {
    float: none;
    margin-left: $medium-space;
  }
  .condition-label {
    label[for="conditions_to_approve[terms-and-conditions]"] {
      text-align: left;
    }
  }
}
.cart-empty {
  .cart-summary {
    opacity: 0.7;
  }
  .cart-container {
    min-height: 37.1rem;
  }
}
.js-payment-atos {
  display: none;
  .accept-cgv {
    display: none;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
    .accept-cgv {
      display: block;
    }
  }
}

.card {
  a[data-toggle=collapse] {
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}

.order-confirmation-table {
  display: table;
  width: 100%;
  margin-bottom: 1.5em;

  .order-line {
    display: table-row;

    &:last-child > div {
      border-bottom: none;
    }

    & > div {
      display: table-cell;
      vertical-align: middle;
      float: none;
      width: auto;
      padding: 15px;
      border-bottom: rgba($border-color, 0.2) solid 1px;

      &:first-child {
        width: 50px;

        img {
          max-width: 100px;
        }
      }
    }
  }
}

#order-confirmation #main > section {
  margin-bottom: 2em;
}

.payment-option {
  margin-bottom: 1em;
}

.order-options {
  .custom-checkbox {
    display: block;
    margin: 0.5em 0;
  }
}