.homeslider-container {
  float: left;
  width: 375px;
  margin: 0 15px 15px 0;
}
.homeslider-container {overflow: hidden;}
.homeslider-container img {max-width: 100%; vertical-align: bottom;}
.homeslider-container .slide ~ .slide,
.homeslider-container .slide .description {display: none;}
.homeslider-container .bx-wrapper .slide ~ .slide,
.homeslider-container .bx-wrapper .description {display: block;}

.bx-wrapper {
  position: relative;
  
  li {
    position: relative;

    img {
      display: block;
      width: 100%;
    }

    .description {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      max-height: 375px;
      overflow: hidden;
      background: linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0));
      padding: 15px;
      color: #fff;

      h2 {
        font-size: 2em;
        margin: 0;
        padding: 0;
      }
      p {
        padding: 0.5em 0;
        margin: 0;
      }
    }
  }
  
  .bx-controls-direction {
    .homeslider-container & {
      opacity: 0;
      transition: $hover-transition;
    }
    .homeslider-container:hover & {
      opacity: 1;
    }

    a {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(255,255,255,0.85);
      text-indent: -999em;
      overflow: hidden;
      color: black;
      &:after {
        font-family: "pictos";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: none;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;

        content: '\E82C';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        line-height: 50px;
        text-align: center;
        text-indent: 0;
        font-size: 24px;
      }

      &.bx-next {
        left: 50px;
        &:after {
          content: '\E82B';
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: default;
        display: none;

        &:hover {
          &:before {
            border-color: transparent #999 #999 transparent;
          }
        }
      }

      &:hover {
        background: rgba(0,0,0,0.85);
        color: white;
      }
    }
  }

  .bx-pager {
    position:absolute;
    right:0;
    bottom:0;
    font-size:1px;
    background:rgba(0,0,0,0.85);
    padding:9px;
    z-index:100;
    width: auto;
    
    .bx-pager-item {
      display:inline;

      &:first-child a {
        margin: 0;
      }
    }
    a {
      display:inline-block;
      width:6px;
      height:6px;
      overflow:hidden;
      text-indent:-999em;
      background:#666;
      margin:0 0 0 10px;
      vertical-align:bottom;
      cursor: pointer;
      border-radius: 0;

      &.active, &:hover, &:focus {
        background: white;
      }
    }
  }
}