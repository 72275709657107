#header {
  margin-bottom: 27px;

  & > .container {
    position: relative;
    margin: 0 auto;
    z-index: 200;
    padding: 0;

    &:after {
      content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
    }
  }

  #header_logo {
    padding: 0;
    width: 375px;
    margin-right: 5px;
    line-height: 150px;
    float: left;
    min-height: 136px;

    img {
      max-width: 100%;
      max-height: 150px;
    }
  }

  .header-nav1 {
    float: right;
    text-transform: lowercase;
    font-size: 1.1rem;
    position: relative;
    z-index: 2;
    margin-bottom: 30px;

    & > div {
      float: left;
      margin-right: 3px;

      &:last-child {
        margin-right: 0;
      }
    }

    #contact-link a {
      padding: 5px;
      display: inline-block;
    }
  }

  .header-top {
    clear: right;
  }
  
  .dropdown {
    background: $bg-bloc1;
    color: $txt-bloc-color;
    transition: background 200ms ease-in-out 0s;

    &:hover {
      background: $bg-bloc-hover;
    }

    a {
      color: inherit;
    }

    .expand-more {
      display: inline-block;
      padding: 3px 5px;
      color: inherit;

      &:after {
        font-family: "pictos";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: none;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;

        content: '\E810';
        margin-left: 5px;
      }
    }

    ul {
      visibility: hidden;
      position: absolute;
      left: 0;
      min-width: 100%;
      background: $bg-bloc1;
      transition: background 200ms ease-in-out 0s;

      li a {
        display: block;
        color: inherit;
        opacity: 0.75;
        padding: 2px 5px;

        &:hover, &:focus {
          opacity: 1;
        }
      }

      li.current a {
        opacity: 1;
      }
    }

    &:hover ul {
      visibility: visible;
      background: $bg-bloc-hover;
    }
  }

  #search_widget {
    float: right;
    width: 375px;
    min-height: 30px;
    margin-bottom: 32px;
  }

  .header-nav2 {
    float: right;
    width: 375px;
    clear: right;

    &:after {
      content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
    }

    & > div {
      float: left;
      width: 180px;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .blockcart .header {
    & > span:first-child, & > a > span:first-child {
      &:before {
        content: '\e808';
        font-family: "pictos";
        font-size: 1.4em;
        margin-right: 6px;
        line-height: 1;
        font-weight: normal;
      }
    }
    .cart-products-count {
      position: relative;
      top: -7px;
      left: -3px;
      background: $text-color;
      color: $background-color;
      color: white;
      border-radius: 8px;
      height: 15px;
      font-size: 10px;
      text-align: center;
      padding: 0 3px;
    }
    a > .cart-products-count {
      background-color: $btn-primary-bg;
      color: $btn-primary-color;
    }
  }

  .user-info {
    position: relative;
    z-index: 2;
    & > a:first-child:before {
      content: '\f007';
      font-family: FontAwesome;
      font-size: 1.2em;
      margin-right: 5px;
    }
    .logged {
      padding: 4px 10px;
      margin-top: -4px;
      margin-left: -10px;
      transition: $hover-transition;
      display: inline-block;
      &:after {
        font-family: "pictos";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: none;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: middle;
        content: '\E80C';
        font-size: 2.2rem;
      }
    }
    ul {
      position: absolute;
      left: -10px;
      background-color: $menu-hover-bg;
      color: $menu-hover-text;
      max-width: 180px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 200ms ease-in-out, visibility 0s linear 200ms;

      a {
        display: block;
        padding: 5px 10px;
        color: inherit;
        transition: all 200ms ease-in-out;
        text-transform: none;
        opacity: 0.75;

        &:hover, &:focus {
          opacity: 1;
        }
      }
    }
    &:hover {
      ul {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }

      .logged {
        background-color: $menu-hover-bg;
        color: $menu-hover-text;
      }
    }
  }
  
  /* mobile menu */
  #mobile_top_menu_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 40px 20px 20px 50px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
    background: $background-color;
    z-index: 2000;
    overflow: auto;

    &.shown {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }

    #search_widget {
      position: relative;
      left: auto;
      top: auto;
      width: auto;
      min-height: 0;
      margin: 0;
    }

    .js-top-menu {
      margin-bottom: 20px;
    }

    .top-menu {
      margin-bottom: 0;

      & > li:last-child > a[data-depth="0"] {
        border-bottom: none;
      }

      li {
        margin-bottom: 0;
        font-size: 1.4rem;
      }
      a {
        display: block;
        padding: 10px 0;
        min-height: 37px;
        border-bottom: solid 1px rgba($border-color, 0.2);
        padding-right: 50px;
      }
      li li a {
        padding-left: 15px;
      }
      li li li a {
        padding-left: 30px;
      }
      .has_submenu {
        position: relative;
      }
      .toggle_submenu {
        position: absolute;
        height: 37px;
        width: 40px;
        cursor: pointer;
        right: 0;
        top: 0;
        text-indent: -999em;
        overflow: hidden;
        line-height: 37px;
        text-align: left;

        &:before {
          content: '\f107';
          font-size: 20px;
          font-weight: normal;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          text-indent: 0;
          font-family: FontAwesome;
        }
      }
      .opened > .toggle_submenu:before {
        content: '\f106';
      }
      .sub-menu {
        display: none;
      }
    }
    .row {
      margin-bottom: 20px;
    }
  }
  #toggle_menu {
    position: absolute;
    left: -8px;
    bottom: 0;
    display: none;
    width: 40px;
    height: 29px;
    padding: 8px;
    cursor: pointer;

    & > i {
      display: block;
      height: 2px;
      background-color: $link-color;
      margin-bottom: 4px;
      border-radius: 4px;
      transition: background-color 0.15s ease-in-out;
    }
    &:hover {
      & > i {
        background-color: $link-hover-color;
      }
    }
  }
  #menu_close {
    position: absolute;
    top: 23px;
    left: 4px;
    cursor: pointer;
    height: 30px;
    width: 40px;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }

    &:before {
      content: '\f00d';
      font-family: FontAwesome;
    }
  }
}