#search_widget {
  position: relative;

  input[type=text] {
    background: inherit;
    box-shadow: none;
    display: block;
    height: 2.5rem;
    padding: 0 30px 0 5px;
    width: 100%;
    max-width: none;
    height: 3.1rem;
    line-height: 2.9rem;
    font-size: 1.2rem;
    border-color: $text-color;
    color: inherit;
  }

  button[type=submit] {
    background: none;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    color: inherit;
    opacity: 0.5;
    width: 36px;
    text-align: center;
    padding: 0;
    height: 3.1rem;
    line-height: 3.1rem;
    transition: opacity 200ms ease-in-out;

    span {
      display: none;
    }

    &:before {
      content: "\f002";
      display: block;
      font-family: "FontAwesome";
      font-size: 16px;
      width: 100%;
      text-align: center;
    }
    &:hover {
      opacity: 1;
    }
  }
}