/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
}
.table-labeled {
  th {
    vertical-align: middle;
  }
  td {
    vertical-align: middle;
  }
  .label {
    font-weight: 400;
    border-radius: 0;
    font-size: inherit;
  }
}
.page-order {
  .table {
    margin-bottom: 0;
  }
  table {
    th {
      padding: 0.8rem;
    }
    td {
      padding: 0.8rem;
    }
    thead {
      th {
        text-align: center;
      }
    }
  }
}
#authentication {
  .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
  }
}
#identity,
#authentication {
  .radio-inline {
    padding: 0;
    .custom-radio {
      margin-right: 0;
    }
  }
}
/*** Most of the customer accpunt pages ***/
.page-customer-account {
  #content {
    .order-actions {
      a {
        padding: 0 0.2rem;
      }
    }
    .forgot-password {
      text-align: center;
      font-size: $font-size-sm;
      margin-top: 1.6rem;
      padding-bottom: 1.5rem;
    }
    .no-account {
      text-align: center;
      font-size: $base-font-size;
    }
  }
}
/*** Login page ***/
.page-authentication {

}
/*** Addresses page ***/
.page-addresses {
  .address {
    background: $block-bg;
    margin-bottom: 1.5rem;
    color: $block-text;

    .address-body {
      padding: 10px 15px;

      address {
        margin-bottom: 0;
      }
    }

    .address-footer {
      border-top: 1px solid rgba($border-color, 0.2);
      padding: 0.8rem 1.5rem;
      text-align: center;
      a {
        margin: 0 0.3rem;
      }
    }
  }
}
/*** Order details page ***/
.page-order-detail {
  font-size: 1.4rem;
  color: $gray-dark;
  .box {
    @include customer-area-base-box;
    margin-bottom: 1.6rem;
  }
  h3 {
    font-size: $base-font-size;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: 1.6rem;
  }
  #order-infos {
    ul {
      margin: 0;
    }
  }
  .addresses {
    margin: 0 -1.5rem;
    h4 {
      font-size: $base-font-size;
      font-weight: 700;
    }
  }
  #order-products {
    &.return {
      margin-bottom: 1.6rem;
      th {
        &.head-checkbox {
          width: 30px;
        }
      }
      td {
        padding: 1.375rem 0.75rem;
        &.qty {
          min-width: 125px;
          .current {
            width: 30%;
            float: left;
            text-align: right;
            padding-right: 0.5rem;
          }
          .select {
            width: 70%;
            float: left;
            margin: -0.625rem 0;
            padding-left: 0.25rem;
            select {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
/*** Order return page ***/
#order-return-infos {
  .thead-default th {
    color: $gray-darker;
  }
}
/*** My account landing page ***/

/*** FOOTER ***/
.my-account-footer {
  padding-top: 15px;
  margin-top: 15px;
  border-top: rgba($border-color, 0.2) solid 1px;

  & > a {
    display: inline-block;
    margin: 0 20px 0 0;
  }
}

.page-customer-account,
.page-my-account,
.page-addresses {
  #content-wrapper.no-sidebar {
    max-width: 765px;
    margin: 0 auto; 
  }
}
.page-history {
  #content {
    max-width: none;
    margin: 0 auto; 
  }
}

.page-my-account {
  #content {
    padding: 0;

    & > .row {
      margin: 0;
    }

    .links {
      margin-right: -15px;
      a {
        float: left;
        width: 180px;
        min-height: 180px;
        margin: 0 15px 15px 0;
        display: block;
        padding: 10px 15px 0;
        transition: all 0.15s ease-in-out;
        position: relative;
        background: $bg-bloc2;
        color: $txt-bloc-color;
        text-align: center;

        i {
          display: none;
        }

        &:before {
          font-family: "pictos";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: none;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;

          content: '\E853';
          display: block;
          text-align: center;
          font-size: 50px;
          margin: 25px 0;
        }

        &#history-linkbefore {content: '\E808';}
        &#addresses-link:before {content: '\e856';}
        &#identity-link:before {content: '\e859';}
        &#order-slips-link:before {content: '\E853';}
        &#log-out-link:before {content: '\e852';}
        //.mailalerts a:before {content: '\E83D';}
        //.favoriteproducts a:before {content: '\e833';}
        //.lnk_wishlist a:before {content: '\e837';}

        &:hover, &:focus {
          background-color: $bg-bloc-hover;
        }
      }
    }
  }
}

.login-form {
  background-color: $block-bg;
  color: $block-text;
  padding: 30px;
  margin: 30px 0;
}