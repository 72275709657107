@font-face {
  font-family: 'FontAwesome';
  src:  url('~font-awesome/fonts/fontawesome-webfont.eot?v=4.6.3');
  src:  url('~font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.6.3') format('embedded-opentype'), 
        url('~font-awesome/fonts/fontawesome-webfont.woff2?v=4.6.3') format('woff2'),
        url('~font-awesome/fonts/fontawesome-webfont.woff?v=4.6.3') format('woff'),
        url('~font-awesome/fonts/fontawesome-webfont.ttf?v=4.6.3') format('truetype'),
        url('~font-awesome/fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pictos';
  src: url('../fonts/pictos.eot?457865423');
  src: url('../fonts/pictos.eot?457865423#iefix') format('embedded-opentype'),
     url('../fonts/pictos.woff?457865423') format('woff'),
     url('../fonts/pictos.ttf?457865423') format('truetype'),
     url('../fonts/pictos.svg?457865423#pictos') format('svg');
  font-weight: normal;
  font-style: normal;
}