.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 5;
  height: 100%;
  width: 100%;
}
.input-container {
  position: relative;
}
.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  &:checked + .radio-label {
    border-color: $border-color;
  }
}
.radio-label {
  background: white;
  display: inline-block;
  padding: 0.2rem $small-space;
  font-weight: 600;
  border: solid 1px rgba($border-color, 0.2);
  transition: border-color 0.15s ease-in-out;  
}
.product-variants-item li:hover .radio-label {
  border-color: $border-color;
}
.product-actions {
  .control-label {
    width: 125px;
    margin-bottom: 0;
    margin-right: 4px;
    float: left;
    padding-top: 4px;
  }
  input[type=text] {
    width: 78px;
    min-width: 150px;
    padding: 3px 7px;
  }

  .add {
    text-align: right;
    // border-bottom: rgba($border-color, 0.2) solid 1px;
    padding-bottom: 20px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
}
.bootstrap-touchspin {
  .form-control {
    padding-right: 30px;
    padding-left: 30px;
  }
  .input-group-btn {
    position: relative;
    width: 0;
    z-index: 10;
  }
  .btn {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 23px;
    text-align: center;
    line-height: 23px;
    background: transparent;
    border: none;
    border-right: rgba($border-color,0.2) solid 1px;
    z-index: 10;
    transition: all 0.2s ease-in-out;
    padding: 0;

    &:hover, &:focus {
      background-color: $text-color;
      color: $background-color;
    }

    &.bootstrap-touchspin-up {
      left: auto;
      right: 0;
      border-right: none;
      border-left: rgba($border-color,0.2) solid 1px;
    }
  }
}
.product-quantity {
  .bootstrap-touchspin {
    .form-control {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  .qty {
    float: left;
    margin-bottom: 10px;
  }
  .add {
    float: left;
    margin-bottom: 0.8rem;
  }
  .input-group-btn-vertical {
    float: left;
  }
  .input-group-btn-vertical {
    .btn {
      padding: 1.1rem 1.0rem 1.0rem 1.0rem;
      i {
        font-size: 1.6rem;
        top: 0.2rem;
        left: 0.3rem;
      }
    }
  }
  .btn-touchspin {
    height: 2.3rem;
  }
}
.product-discounts {
  margin: $extra-large-space 0;
  > .product-discounts-title {
    font-weight: normal;
    font-size: $font-size-sm;
  }
  > .table-product-discounts {
    thead tr th {
      width: 33%;
      padding: $small-space $medium-space;
      background: white;
      border: 0.5rem $gray-light solid;
      text-align: center;
    }
    tbody tr {
      background: $gray-lighter;
      &:nth-of-type(even) {
        background: white;
      }
      td {
        padding: $small-space $medium-space;
        text-align: center;
        border: 0.5rem $gray-light solid;
      }
    }
  }

  &:empty {
    margin: 0;
    padding: 0;
  }
}
.product-prices {
  padding-top: 15px;
  padding-right: 10px;
  text-align: right;

  .current-price {
    font-size: 1.8rem;
    line-height: 1;
    font-family: $second-font;
    font-weight: normal;
    margin-bottom: 1em;

    .tax-shipping-delivery-label {
      display: inline;
    }
  }
}
.product-discount {
  margin-bottom: 1.5em;
  .regular-price {
    text-decoration: line-through;
    font-weight: normal;
    margin-right: $small-space;
  }
}
.has-discount {
  .discount {
    display: inline-block;
  }
}
.tabs {
  clear: both;

  .tab-pane {
    padding-top: 15px;
  }
  .nav-tabs {
    border: none;
    border-bottom: rgba($border-color, 0.2) 1px solid;
    .nav-link {
      opacity: 0.8;
      border: 0 solid transparent;
      font-weight: normal;
      color: $link-color;
      &.active {
        border: none;
        border-bottom: $border-color 3px solid;
        opacity: 1;
        font-weight: bold;
        background: none;
      }
      &:hover, &:focus {
        color: $link-hover-color;
        border: none;
        border-bottom: $border-color 3px solid;
      }
    }
    .nav-item {
      float: left;
      margin-bottom: -0.2rem;
    }
  }
}
.product-cover {
  position: relative;
  margin-bottom: 7px;
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: $border-color;

    .layer {
      opacity: 1;
    }
  }

  img {
    background: white;
  }

  .layer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    color: $text-color;
    text-align: center;
    cursor: pointer;
    @include transition(opacity .2s ease-in-out);

    &:before {
      content: '\f00e';
      font-family: FontAwesome;
      position: absolute;
      bottom: 10px;
      left: 10px;
      width: 50px;
      height: 50px;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      line-height: 50px;
      background: rgba($background-color,0.5);
    }
  }
}
.js-qv-mask .bx-wrapper {
  max-width: none !important;
}
.images-container {
  .bx-wrapper .bx-controls-direction a {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    border-radius: 5px;
    left: auto;
    top: 50%;

    &:after {
      font-size: 16px;
      line-height: 24px;
    }

    &.bx-next {
      right: -8px;
    }
    &.bx-prev {
      left: -8px;
    }
  }
}
#product-modal {
  .modal-dialog {
    max-width: 852px;
  }
  .modal-content {
    background: transparent;
    border: none;
    padding: 0;
    box-shadow: none;
    .modal-body {
      padding: 0;

      &:after {
        content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
      }

      figure {
        margin-right: 102px;
        img {
          width: 100%;
        }
      }

      .product-cover-modal {
        background: white;
      }
      .image-caption {
        background: white;
        padding: 1.0rem 2.0rem;
        p {
          margin-bottom: 0;
        }
      }
      .thumbnails {
        position: relative;
        float: right;
        width: 92px;
      }
      .mask {
        position: relative;
        overflow: hidden;
        max-height: 79rem;
        max-height: 80vh;
        z-index: 1;
        &.nomargin {
          margin-top: 0;
        }
      }
      .product-images {
        li {
          margin-right: 0;
          margin-bottom: 10px;
        }
        img {
          cursor: pointer;
          background: white;
        }
      }
    }
  }
}
#product-modal, .quickview {
  .arrows {
    margin-top: 20px;

    i {
      width: 25px;
      height: 25px;
      position: relative;
      display: inline-block;
      margin-right: 5px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.85);
      text-indent: -999em;
      overflow: hidden;
      color: black;
      cursor: pointer;

      &:before {
        content: '';
        height: 10px;
        width: 10px;
        border: solid 1px #999;
        border-color: transparent #999 #999 transparent;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -2px 0 0 -6px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }

      &:hover:before {
        border-color: transparent black black transparent;
      }

      & + i {
        &:before {
          margin-top: -5px;
          margin-left: -4px;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
}
#blockcart-modal {

  .btn {
    margin-bottom: 10px;
  }

  .modal-header {
    background: $btn-primary-color;
    color: $btn-primary-bg;
  }
  .modal-body {
    padding: 5.0rem 3.0rem;
    .divide-right span {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
  .modal-dialog {
    max-width: 765px;
    width: 100%;
  }
  .modal-title {
    color: inherit;
    margin: 0;
    text-transform: none;
    text-align: center;

    &:before {
      content: '\f00c';
      font-family: FontAwesome;
      margin-right: 5px;
      font-weight: normal;
    }
  }
  .product-image {
    max-width: 100%;
  }
  .h2 {
    text-transform: none;
  }
  .cart-products-count {
    font-size: 1.8rem;
  }
  .cart-content {
    padding-left: $extra-large-space;
    button {
      margin-right: 0.5rem;
    }
  }
  .divide-right {
    border-right: rgba($border-color, 0.2) 1px solid;
  }
}
.product-images {
  margin: 0;

  &:after {
    content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
  }
  
  > li.thumb-container {
    float: left;
    width: 90px;
    margin-right: 5px;
    cursor: pointer;
    position: relative;

    &.selected:before {
      // border-color: black;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      max-width: 100%;
      height: auto;
      display: inline-block;
      vertical-align: top;
    }
  }
}
#product-availability {
  display: block;
  margin-top: 1em;
  font-size: 0.85em;

  &:before {
    content: '\f00c';
    font-family: FontAwesome;
    color: $brand-success;
    margin-right: 3px;
  }
  &.last_remaining_items:before {
    content: '\f071';
    color: $brand-warning;
  }
  &.product-unavailable:before {
    content: '\f06a';
    color: $brand-danger;
  }
}

.product_attributes {
  padding: 20px 0 10px 10px;
  border-top: rgba($border-color, 0.2) solid 1px;
  border-bottom: rgba($border-color, 0.2) solid 1px;
  margin-top: 10px;
}

#product-details {
  & > div {
    margin-bottom: 15px;
  }
  .label {
    font-weight: bold;
  }
}
.product-features {
  margin-top: $medium-space;
  > dl.data-sheet {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    dd.value,
    dt.name {
      flex: 1 0 40%;
      font-weight: normal;
      background: $gray-light;
      padding: $small-space;
      margin: 0;
      word-break: break-all;
      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }
    dt.name {
      font-weight: bold;
    }
  }
}
.product-variants {
  > .product-variants-item {
    margin: 0 0 10px;

    &:last-child {
      // margin-bottom: 0;
    }
    select {
      min-width: 150px;
      width: auto;
    }
    & > ul {
      overflow: auto;
      margin: 0;
    }
    ul li {
      float: left;
      margin: 0 5px 5px 0;
    }
    .color {
      width: 23px;
      height: 23px;
      background: $background-color;
      margin: 0;
      display: block;
      position: relative;
      transition: all 0.2s ease-in-out;
      border: none;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: solid 2px $background-color;
        z-index: 1;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: solid 1px rgba($border-color, 0.2);
        z-index: 2;
        transition: border-color 0.15s ease-in-out;
      }
    }
    ul.color_pick {
      li {
        margin: 0 5px 5px 0;

        &:hover .color:before {
          border-color: $border-color;
        }
      }
    }
    .input-color {
      &:checked + span:before {
        border-color: $border-color;
      }
    }
  }
}

.product-minimal-quantity:empty {
  display: none;
}

.product-customization {
  // @include box-shadow;
  margin: $extra-large-space 0;
  .product-customization-item {
    margin: $medium-space 0;
  }
  .product-message {
    background: $gray-light;
    border: none;
    width: 100%;
    height: 5.0rem;
    resize: none;
  }
  .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: 4.2rem;
    overflow: hidden;
    position: absolute;
  }
  .custom-file {
    position: relative;
    background: $gray-light;
    width: 100%;
    height: 4.2rem;
    line-height: 4.2rem;
    text-indent: 1.0rem;
    display: block;
    color: $gray;
    margin-top: $medium-space;
    button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  small {
    color: $gray;
  }
}
.product-pack {
  margin-top: $extra-large-space;
  .pack-product-container {
    display: flex;
    justify-content: space-around;
    .pack-product-name {
      flex: 0 0 50%;
      font-size: 1.4rem;
    }
    .pack-product-quantity {
      border-left: $gray-light 2px solid;
      padding-left: $small-space;
    }
    .pack-product-name,
    .pack-product-price,
    .pack-product-quantity {
      display: flex;
      align-items: center;
    }
  }
}
.product-refresh {
  margin-top: $medium-space;
}
.social-sharing {
  margin-bottom: 20px;

  & > span {
    display: inline-block;
    vertical-align: middle;
  }
  ul {
    margin-bottom: 0;
    display: inline;
  }
  li {
    display: inline-block;
    vertical-align: middle;
    height: 4.0rem;
    width: 4.0rem;
    border-radius: 50%;
    display: inline-block;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 3.2rem 3.2rem;
    background-position: 0.4rem 0.4rem;
    cursor: pointer;

    a {
      display: block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;
      position: relative;

      &:before {
        content: '\f09a';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: 0;
        text-align: center;
        line-height: 4.0rem;
        font-family: FontAwesome;
        font-size: 2.0rem;
        font-weight: normal;
      }
    }

    &.twitter a:before {content: '\f099';}
    &.googleplus a:before {content: '\f0d5';}
    &.pinterest a:before {content: '\f231';}

  }
}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
    }
  }
  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0;
    img.product-cover-modal {
      width: 100%;
    }
    .arrows {
      display: none;
    }
  }
  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }
}

#product {
  .page-content {
    position: relative;

    .product-flags {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 10;

      li {
        display: inline-block;
        background: $btn-primary-bg;
        color: $btn-primary-color;
        padding: 2px 8px;
        text-transform: uppercase;
        font-size: 1.1rem;
        line-height: normal;
        vertical-align: top;
        font-weight: 500;
        margin-bottom: 3px;

        &.new {
          background-color: $border-color;
          color: $background-color;
        }
      }
    }
  }
  h1 .brand {
    font-weight: bold;
    &:after {
      content: ' ';
    }
  }
}

.pb-left-column {
  float: left;
  width: 375px;
  margin: 0 15px 10px 0;

  #content-wrapper[data-nb-cols="3"] & {
    width: 180px;
    float: left;
    margin-right: 15px;
    .product-images > li.thumb-container {
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  #content-wrapper[data-nb-cols="2"] & {
    float: none;
  }
}
.pb-center-column {
  overflow: auto;
  margin-bottom: 30px;
}

#product-details {
  & > div {
    margin-bottom: 15px;
  }
}
