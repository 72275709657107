.products {
  margin: 0 -15px 0 0;

  .product-miniature {
    width: 375px;
    float: left;
    margin: 0 15px 15px 0;
    position: relative;
    list-style: none;
    overflow: hidden;

    & > a {
      display: block;

      @supports(display: flex) {
        display: flex;
        justify-content: space-between;

        .sidebar & {
          display: block;
        }
      }

      &:hover, &:focus {
        .product-description {
          background-color: $bg-bloc-hover;
          &:after {
            border-right-color: $bg-bloc-hover;
          }
        }
      }
    }

    img {
      max-width: 100%;
    }

    .thumbnail-container {
      position: relative;
      overflow: hidden;
      width: 180px;
      float: left;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .discount-percentage {
      display: inline-block;
      font-size: 1.1rem;
      background: $btn-primary-bg;
      color: $btn-primary-color;
      padding: 2px 4px;
    }

    .product-description {
      float: right;
      width: 180px;
      min-height: 180px;
      padding: 10px 15px;
      position: relative;
      z-index: 2;
      transition: $hover-transition;
      color: $txt-bloc-color;
      background: $bg-bloc1;

      .sidebar & {
        min-height: 0;
      }

      #content-wrapper #subcategories & {
        background-color: $bg-bloc-hover;

        &:after {
          border-right-color: $bg-bloc-hover;
        }

        .product-title {
          font-weight: bold;
        }
      }

      &:after {
        content: "";
        width: 0; height: 0;
        border: 10px solid $bg-bloc1;
        left: -20px;
        top: 50%;
        margin-top: -10px;
        position: absolute;
        border-color: transparent $bg-bloc1 transparent transparent;
        transition: $hover-transition;
      }
    }
    &:nth-child(3n+1) .product-description,
    &:nth-child(5) .product-description {
      background-color: $bg-bloc2;
      &:after {
        border-right-color: $bg-bloc2;
      }
    }
    &:nth-child(4) .product-description {
      background-color: $bg-bloc1;
      &:after {
        border-right-color: $bg-bloc1;
      }
    }

    @keyframes fadeIn { 0%, 66% { opacity:0; } 100% { opacity:1; }}
    @-webkit-keyframes fadeIn { 0%, 66% { opacity:0; }100% { opacity:1; }}
    @keyframes fadeIn2 { 0%, 70% { opacity:0; } 100% { opacity:1; }}
    @-webkit-keyframes fadeIn2 { 0%, 70% { opacity:0; }100% { opacity:1; }}
    @keyframes fadeIn3 { 0%, 73% { opacity:0; } 100% { opacity:1; }}
    @-webkit-keyframes fadeIn3 { 0%, 73% { opacity:0; }100% { opacity:1; }}
    @keyframes fadeIn4 { 0%, 61% { opacity:0; } 100% { opacity:1; }}
    @-webkit-keyframes fadeIn4 { 0%, 61% { opacity:0; }100% { opacity:1; }}

    .thumbnail-container,
    .product-description,
    .functional-buttons {
      -webkit-animation: fadeIn 1s ease-in 0s 1;
      -webkit-animation-fill-mode: forwards;
      animation: fadeIn 1.5s ease-in 0s 1;  
      animation-fill-mode: forwards;  
    }

    &:nth-child(2n+1) .product-description,
    &:nth-child(2n+1) .functional-buttons {
      -webkit-animation-name: fadeIn2;
      -webkit-animation-duration: 1.7s;
      animation-name: fadeIn2;
      animation-duration: 1.7s;  
    }
    &:nth-child(2n+2) .product-description,
    &:nth-child(2n+2) .functional-buttons {
      -webkit-animation-name: fadeIn3;
      -webkit-animation-duration: 1.9s;
      animation-name: fadeIn3;
      animation-duration: 1.9s;  
    }
    &:nth-child(2n+1) .thumbnail-container,
    &:nth-child(3n+2) .product-description,
    &:nth-child(3n+2) .functional-buttons {
      -webkit-animation-name: fadeIn4;
      -webkit-animation-duration: 1.3s;
      animation-name: fadeIn4;
      animation-duration: 1.3s;  
    }

    .product-title {
      margin: 0 0 0.6em;
      font-size: 1.4rem;
      line-height: 1.2;
    }

    .product-desc {
      line-height: 1.4;
      margin-bottom: 1.4em;
    }

    .product-price-and-shipping {
      font-size: 1.4rem;
      text-transform: uppercase;
      position: absolute;
      left: 15px;
      bottom: 10px;
      right: 53px;
    }

    .regular-price {
      text-decoration: line-through;
      font-size: 0.85em;
      display: block;
    }

    .discount-percentage {
      display: none;
    }

    .product-flags {
      position: absolute;
      bottom: 5px;
      left: 5px;

      li {
        display: inline-block;
        padding: 1px 3px;
        font-size:10px;
        background: $bg-bloc1;
        color: $txt-bloc-color;
        text-transform: uppercase;

        &.discount, &.online-only {
          background: $bg-bloc2;
        }
      }
    }

    .functional-buttons {
      opacity: 1;
      transition: $hover-transition;
      position: absolute;
      right: 8px;
      bottom: 6px;
      color: $txt-bloc-color;
      z-index: 3;

      a, button {
        color: inherit;
        vertical-align: middle;
        padding: 5px;
        font-size: 1.2em;
        display: inline-block;
      }
      form {
        display: inline;
      }
    }
    &:hover {
      .functional-buttons {
        opacity: 1;
      }
    }
    .quick-view {
      span {
        display: none;
      }
      &:before {
        font-family: FontAwesome;
        content: '\f06e';
        font-size: 0.85em;
      }
    }

    .add-to-cart-list {
      background: none;
      border: none;
      cursor: pointer;
      
      span {
        display: none;
      }
      &:before {
        font-family: pictos;
        content: '\E808';        
        vertical-align: middle;
      }
    }

    .highlighted-informations {
      display: none;
    }
  }

  .bx-wrapper &.jq_carousel {
    .product-miniature {
      margin: 0;
      clear: none !important;
    }
  }
}

#content-wrapper .block-product-list {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: rgba($border-color, 0.2) solid 1px;
  padding-top: 20px;
  clear: both;

  &.clearfix:after {
    content: none;
  }
}
#index #content-wrapper .homeslider-container + .block-product-list,
#index #content-wrapper .block-product-list.featured-products {
  border-top: none;
  padding-top: 0;
  clear: none;

  .home-title {
    display: none;
  }
}

// product list on product page, and crosselling
.block-product-list {
  .page-product #content-wrapper &,
  &.cross-selling {
    .bx-controls-direction {
      opacity: 1;
      a {
        top: -50px;
        right: 25px;
        left: auto;
        height: 25px;
        width: 25px;
        background: $background-color;
        color: $text-color;
        opacity: 0.65;
        &:hover {
          background: $background-color;
          color: $text-color;
          opacity: 1;
        }

        &:after {
          font-size: 16px;
          line-height: 25px;
        }

        &.bx-next {
          right: 0;
        }
      }
    }

    border-top: none;
    padding-top: 0;

    & > h2 {
      border-bottom: rgba($border-color, 0.2) solid 1px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      padding-right: 50px;
    }
    .products {
      &:after {
        content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
      }
    }
    .product-miniature {
      width: 180px;

      & > a {
        display: block;
      }

      .thumbnail-container {
        float: none;
        width: auto;
        margin-bottom: 11px;
      }
      .product-description {
        float: none;
        width: auto;
        background: none !important;
        color: inherit !important;
        height: auto;
        min-height: 0;
        padding: 0;

        &:after {
          content: none;
        }
      }
      .product-title {
        text-transform: none;
      }
      .product-desc {
        display: none;
      }
      .product-price-and-shipping {
        position: static;
        font-size: 1.2rem;
      }
      .functional-buttons {
        display: none;
      }
      .regular-price {
        display: inline-block;
        font-size: inherit;
      }
    }
  }
}

.bx-wrapper .products {
  margin: 0;
}