.lang-rtl {
  text-align: right;
  * {
    direction: rtl;
  }
  ul {
    padding-right: 0;
  }
  .homeslider-container {
    float: right;
    margin-left: 15px;
    margin-right: 0;
    direction: ltr;

    .bx-wrapper .bx-controls-direction a {
      direction: rtl;
    }
  }
  .bx-viewport {
    * {
      direction: ltr;
    }
    &, & > ul {
      direction: ltr;
    }
    & > ul > li {
      direction: rtl;
    }
  }

  .products {
    margin-right: 0;
    margin-left: -15px;
    .product-miniature {
      float: right;
      margin-left: 15px;
      margin-right: 0;
    }
  }
  .bx-wrapper .products {
    margin: 0;
  }

  #header #header_logo {
    text-align: left;
  }
  .products .product-miniature .product-price-and-shipping {
    right: 15px;
    left: 53px;
  }
  .products .product-miniature .functional-buttons {
    left: 213px;
    right: auto;
  }

  #header .dropdown .expand-more:after,
  #header .dropdown .expand-more:after,
  #header .blockcart .header > span:first-child:before,
  #header .blockcart .header > a > span:first-child:before {
    margin-left: 0;
    margin-right: 5px;
  }

  .input-group {
    direction: ltr !important;
    text-align: right;
  }
  .product-quantity .qty {
    float: right;
  }
  .product-actions .control-label {
    float: right;
    margin-right: 0;
    margin-left: 4px;
  }

  .product-variants > .product-variants-item ul li {
    float: right;
  }
  .product-variants > .product-variants-item ul.color_pick li {
    margin-right: 0;
    margin-left: 5px;
  }

  .quickview .mask {
    margin-left: 0;
    margin-right: 1rem;
  }

  .tabs .nav-tabs .nav-item {
    float: right;
  }

  #wrapper .breadcrumb ol {
    padding-right: 0;
  }

  @media (min-width: 544px) {
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
      float: right;
    }
  }

  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: right;
  }

  @media (min-width: 544px) {
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
      float: right;
    }
  }

  @media (min-width: 768px) {
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
      float: right;
    }
  }

  @media (min-width: 992px) {
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
      float: right;
    }
  }

  @media (min-width: 1200px) {
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12 {
      float: right;
    }
  }

  .input-group .form-control {
    float: right;
  }
  .block-category.has-img {
    float: right;
    margin-left: 15px;
    margin-right: 0;
  }
  .products-selection .products-sort {
    float: left;
  }

  ul.page-list li:first-child {
    border-left-width: 1px;
  }
  ul.page-list li:last-child {
    border-left-width: 0;
  }

  .block-category.has-img h1 {
    right: 0;
    left: auto;
  }

  .js-search-filters-clear-all {
    text-align: right;
  }

  &.page-product #content-wrapper .block-product-list .bx-controls-direction a,
  .block-product-list.cross-selling .bx-controls-direction a {
    right: auto;
    left: 0;
  }
  &.page-product #content-wrapper .block-product-list .bx-controls-direction a.bx-next,
  .block-product-list.cross-selling .bx-controls-direction a.bx-next {
    right: auto;
    left: 25px;
  }

  &.page-product #content-wrapper .block-product-list > h2,
  .block-product-list.cross-selling > h2 {
    padding-left: 50px;
    padding-right: 0;
  }

  .pull-xs-left {
    float: right !important;
  }
  .pull-xs-right {
    float: left !important;
  }

  &#checkout section.checkout-step .payment-options .custom-radio {
    margin-right: 0;
    margin-left: 2rem;
  }
  .custom-checkbox input[type="checkbox"] + span {
    margin-right: 0;
    margin-left: 3px;
  }
  body#checkout .condition-label {
    margin-left: 0;
    margin-right: 4rem;
  }
  .custom-checkbox input[type="checkbox"] {
    right: 0;
  }

  #header .user-info ul {
    right: 0;
    left: auto;
    min-width: 100%;
  }

  .footer-container .footer > div {
    float: right;
  }

  @for $i from 2 through 8 {
    $j: $i - 1;
    $k: $i - 1;
    .container[data-nb-cols="#{$i}"] .footer {
      & > div {
        &:nth-child(#{$i}n+1) {
          clear: right;
        }
        &:nth-last-child(-n+#{$k}):first-child {
          margin-right: 0;
          margin-left: 195px;
        }
        &:nth-last-child(-n+#{$k}):first-child ~ div:last-child {
          float: left;
        }
      }
    }
  }

  .bx-wrapper .bx-pager .bx-pager-item:first-child a {
    margin-left: 10px;
  }
  .bx-wrapper .bx-pager .bx-pager-item:last-child a {
    margin: 0;
  }

  #header #mobile_top_menu_wrapper .top-menu .toggle_submenu {
    text-align: right;
    right: auto;
    left: 0;
  }
  #header #mobile_top_menu_wrapper .top-menu a {
    padding-right: 0;
    padding-left: 50px;
  }
}