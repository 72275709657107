#search_filters {
  & > h4:first-child {
    @extend .block_title;
  }

  .collapse {
    display: block;
    overflow: hidden;
  }

  .facet {
    margin-bottom: 10px;

    select {
      width: 100%;
    }

    .facet-title {
      font-size: 1.4rem;
      padding-bottom: 5px;
      border-bottom: 1px dotted rgba($block-text, 0.5);
      display: block;
      font-weight: normal;
      text-transform: none;
    }

    li {
      font-size: 1.1rem;
      padding: 2px 0;
    }
  }
}
.filter-block {
  margin-bottom: 5px;
}
#_desktop_search_filters_clear_all {
  margin-bottom: 15px;
}
.js-search-filters-clear-all {
  text-transform: none;
  padding: 0;
  display: block;
  width: 100%;
  text-align: left;
  text-decoration: underline;
  font-size: inherit;
  background: none;
}

#js-active-search-filters.hide {
  display: none;
}
#js-active-search-filters {
  li {
    font-size: 1.1rem;
  }
}