@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "components/override_bootstrap";
@import "partials/_functions";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/imageslider";
@import "components/productlist";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/contact";
@import "components/errors";
@import "components/faceted-search";
@import "components/photoswipe";
@import "components/photoswipe-default-skin";

/*** HEADER ***/
@import "partials/header";

/*** WRAPPER ***/
#content-wrapper {
  &:after {
    content: ""; display: block; overflow: hidden; clear: both; visibility: hidden;
  }
  
  &.left-column, &.right-column {
    width: 1155px;
    float: left;
  }
}
#wrapper {
  padding-bottom: 40px;
  .banner {
    margin-bottom: 2.4rem;
    display: block;
    img {
      box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
    }
  }
  .breadcrumb {
    background: transparent;
    padding: 0;
    margin-top: -27px;
    margin-bottom: 1.0rem;
    font-size: 1.2rem;
    &[data-depth="1"] {
      display: none;
    }
    ol {
      padding-left: 0;
      margin-bottom: 0;
    }
    li {
      display: inline;
      &::after {
        content: " > ";
        padding: 0 5px;
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }
}
/*** MAIN ***/
#main {
  .page-header {
    margin-bottom: 2.5rem;
  }
  .page-content {
    margin-bottom: 2.5rem;
    padding: 0;
    background: none;
    color: inherit;
  }
  .page-footer {
    margin-bottom: 2.5rem;
  }
}

/*** Sidebar ***/
.sidebar {
  float: left;
  width: 375px;
  margin-right: 15px;

  .layout-both-columns & {
    width: 180px;
  }

  .bx-wrapper {
    padding-bottom: 25px;

    .bx-controls {
      position: absolute;
      left: 0;
      bottom: 0;

      .bx-controls-direction a {
        width: 25px;
        height: 25px;
        top: 0;
        left: 0;
        position: relative;
        margin: 0;
        display: inline-block;
        margin-right: 5px;
        border-radius: 5px;

        &:after {
          content: '\E82D';
          font-family: "pictos";
          font-size: 16px;
          line-height: 25px;
        }

        &.bx-next:after {
          content: '\E810';
        }
      }
    }
  }

  & > div > h2:first-child,
  & > section > h2:first-child,
  & > div > h4:first-child,
  & > section > h4:first-child,
  .block_title {
    @extend %block-title;
  }

  &.sidebar-right-column {
    float: right;
    margin-right: 0;
  }

  & > div, & > section {
    background: $block-bg;
    color: $block-text;
    float: left;
    width: 180px;
    clear: left;
    padding: 7px 10px;
    margin-bottom: 15px;

    &:nth-child(2n) {
      clear: right;
      float: right;
    }
  }

  #search_filters_wrapper {
    background-color: lighten($block-bg, 7%);
  }

  #search_filters_wrapper:first-child {
    float: right;
    clear: right;

    & ~ div, & ~ section {
      float: left;
      clear: left;
    }
  }

  &.sidebar-right-column > #search_filters_wrapper:first-child {
    float: left;
    clear: left;

    & ~ div, & ~ section {
      float: right;
      clear: right;
    }
  }

  .block-categories {
    ul {
      margin: 0;
    }

    li {
      position: relative;
      border-top: 1px solid rgba($block-text, 0.5);

      a {
        display: block;
        padding: 3px 3px 3px 15px;
        position: relative;

        &:before {
          content: "\f105";
          font-family: "FontAwesome";
          position: absolute;
          top: 4px;
          left: 3px;
        }
      }

      li {
        font-size: 0.9em;

        a {
          padding-left: 28px;

          &:before {
            left: 15px;
          }
        }

        li {
          a {
            padding-left: 43px;

            &:before {
              left: 30px;
            }
          }
        }
      }
    }

    & > ul > li:first-child {
      border-top: none;
    }
  }

  /* product list in sidebar */
  .products {
    .product-miniature {
      float: none;
      width: auto;
      margin: 0 0 10px;
      border-bottom: rgba($border-color, 0.2) solid 1px;
      min-height: 90px;

      & > a:hover, & > a:focus {
        .product-description {
          background:none;
        }
      }

      .thumbnail-container {
        float: left;
        width: 48%;
        border-color: transparent;
        -webkit-animation: none;
        animation: none;

        img {
          max-width: 100%;
          width: auto;
          height: auto;
        }

        .product-flags {
          display: none;
        }
      }
      .functional-buttons {
        display: none;
      }
      .thumbnail-container + .product-description {
        margin-left: 52%;
      }
      .product-description {
        padding: 0;
        background: none;
        color: inherit;
        float: none;
        font-size: 1.2rem;
        height: auto;
        width: auto;
        -webkit-animation: none;
        animation: none;

        &:after {
          content: none;
        }
        .product-title {
          font-size: inherit;
          text-transform: none;
          line-height: 1;
        }
        .product-price-and-shipping {
          font-size: inherit;
          position: static;

          .price {
            font-size: inherit;
            font-family: inherit;
          }
        }

        .product-desc {
          font-size: 1.1rem;
          opacity: 0.75;
          display: none;
        }
      }
    }

    &.jq_carousel {
      .product-miniature {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.sidebar > div,
.sidebar > section,
.active_filters,
.card,
.login-form {
  a {
    color: inherit;
  }
}

// Layer displayed before modal is displayed (for exemple after clicking the add to cart button)
#layer-waiting-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1030;
  background: linear-gradient(#556270, #c02942);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out, visibility 0s linear 0.15s;

  .js-waiting-modal & {
    opacity: 0.7;
    visibility: visible;
    transition-delay: 0s;
  }
  .modal-open & {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s ease-in-out, visibility 0s linear 0.15s;
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 60px;
  margin-left: -32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*** FOOTER ***/
@import "partials/footer";

/*** RTL part ***/
@import "partials/_rtl";

/*** Responsive part ***/
@import "partials/_responsive";