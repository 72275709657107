.contact-form {
  padding: 15px;
  input[type=text], input[type=email], input[type=password], input[type=tel], textarea, select.form-control {
    max-width: none;
  }
}
.contact-rich {
  .icon {
    display: none;
  }
}
