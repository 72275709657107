.footer-container {
  font-size: 1.1rem;

  & > .container:first-child {
    padding: 20px 0;
    border-top: solid 2px $border-color;
    margin-bottom: 15px;
  }
  .footer {
    margin-bottom: 15px;
    margin-right: -15px;

    & > div {
      float: left;
      margin: 0 0 15px;
      padding: 0 15px 0 0;
      width: 195px;
    }
  }
  @for $i from 2 through 8 {
    $j: $i - 1;
    $k: $i - 1;
    .container[data-nb-cols="#{$i}"] .footer {
      & > div {
        &:nth-child(#{$i}n+1) {
          clear: left;
        }
        &:nth-last-child(-n+#{$k}):first-child {
          margin-right: 195px;
        }
        &:nth-last-child(-n+#{$k}):first-child ~ div:last-child {
          float: right;
        }
      }
    }
  }
  h3 {
    font-size: 1.4rem;
    line-height: normal;
    margin-bottom: 7px;
    margin-top: -4px;
    padding: 0;
    text-transform: uppercase;
    font-weight: normal;
    font-family: $second-font;
  }

  li {
    margin-bottom: 0.4em;
  }

  .block-social {
    li {
      margin: 0 9px 9px 0;
      float: left;

      &:nth-child(4n) {
        margin-right: 0;
      }

      a {
        display: block;
        overflow: hidden;
        position: relative;
        color: $txt-bloc-color;
        background: $bg-bloc1;
        transition: $hover-transition;
        padding: 0;
        text-align: center;
        width: 38px;
        height: 38px;
        line-height: 38px;

        span {
          display: none;
        }

        &:hover, &:focus {
          background-color: $bg-bloc-hover;
        }
        &:before {
          content: '';
          font-family: FontAwesome;
          display: inline-block;
          font-size: 18px;
          font-weight: normal;
        }
      }
    }
    li.facebook a:before {content: '\f09a';}
    li.twitter a:before {content: '\f099';}
    li.googleplus a:before {content: '\f0d5';}
    li.google a:before {content: '\f1a0';}
    li.instagram a:before {content: '\f16d';}
    li.pinterest a:before {content: '\f231';}
    li.youtube a:before {content: '\f167';}
    li.vimeo a:before {content: '\f27d';}
    li.rss a:before {content: '\f09e';}
    li.tumblr a:before {content: '\f173';}
  }

  .container {
    div.block_newsletter {
      .input-wrapper {
        position: relative;
        margin-bottom: 10px;

        input[type=text], input[type=email] {
          width: 100%;
          border-color: $text-color;
          padding-top: 3px;
          padding-bottom: 3px;
          padding-right: 32px;
          background:inherit;
          color: inherit;
        }

        button[type=submit] {
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 25px;
          padding: 0;
          margin: 0;
          background: none;
          text-indent: -999em;
          overflow: hidden;
          font-size: 1px;
          border: none;
          color: inherit;
          box-shadow: none;

          &:after {
            content: '\E819';
            font-family: "pictos";
            text-indent: 0;
            text-align: center;
            font-weight: normal;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            line-height: 25px;
            font-size: 16px;
          }
        }
      }
    }
  }
}