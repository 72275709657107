@mixin search-box {
  form {
    position: relative;
    input[type=text] {
      &:focus {
        outline: 3px $brand-primary solid;
        color: $gray-darker;
        background: white;
        + button .search {
          color: $brand-primary;
        }
      }
      border: none;
      padding: 10px;
      min-width: 255px;
      background: $gray-light;
      color: $gray;
      @include placeholder {
        color: rgba($gray, 0.5);
        text-indent: 1.0rem;
      }
    }
    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: 0.5rem;
      right: 0.2rem;
      color: $gray-dark;
      .search:hover {
        color: $brand-primary;
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
}

%block-title {
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0;
  line-height: normal;
  margin: 0;
  margin: 0 0 15px;
  font-family: $second-font;
  font-weight: bold;

  a {
    color: inherit;
  }
}