#block-reassurance{
  padding: 0;
  margin-bottom: 20px;
  
  img{
    opacity: .8;
    width: 2.5rem;
    margin-right: 0.3rem;
  }
  ul {
    margin: 0;

    li {
      display: inline-block;
      margin-right: 20px;
    }
  }
  li .block-reassurance-item {
    padding: 5px 0;
  }
  #product &{
    span{
      font-weight: normal;
      font-size: inherit;
    }
  }
}
